import Vue from "vue";
import Router from "vue-router";

import layout from "../layout";

Vue.use(Router);

const Login = async() => import(/* webpackChunkName: "auth" */ '@/pages/auth/Login');
const Accounts = async() => import(/* webpackChunkName: "auth" */ '@/pages/auth/Accounts');
const Asset = async() => import(/* webpackChunkName: "asset" */ '@/pages/dashboard/Asset');
export default new Router({
  linkExactActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  //mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      //redirect: '/auth-pages/login',
      component: layout,
      children: [
        {
          path: "/",
          name: "dashboard",
          component: async () => import("@/pages/dashboard")
        },
        {
          path: "/asset/:symbol",
          name: "asset",
          component: Asset
        },
        {
          path: "/fees",
          name: "fees",
          component: async () => import("@/pages/dashboard/Fees")
        },
        {
          path: "/wallet",
          name: "wallet",
          component: async () => import("@/pages/dashboard/Wallet")
        },
        {
          path: "/account/:account",
          name: "account",
          component: async () => import("@/pages/dashboard/Account")
        },
        {
          path: "/wallet/deposit/:symbol",
          name: "wallet-deposit",
          component: async () => import("@/pages/dashboard/WalletDeposit")
        },
        {
          path: "/wallet/withdraw/:symbol",
          name: "wallet-withdraw",
          component: async () => import("@/pages/dashboard/WalletWithdraw")
        },
        {
          path: "/smartholder",
          name: "smartholder",
          component: async () => import("@/pages/dashboard/SmartHolder")
        },
        {
          path: "/lp",
          name: "lp",
          component: async () => import("@/pages/dashboard/Lp")
        },
        {
          path: "/pools",
          name: "pools",
          component: async () => import("@/pages/dashboard/Lp")
        },
        {
          path: "/lp-detail/:asset",
          name: "lpdetail",
          component: async () => import("@/pages/dashboard/LpDetail")
        },
        {
          path: "/pool/:asset",
          name: "pool",
          component: async () => import("@/pages/dashboard/LpDetail")
        },
        {
          path: "/download",
          name: "download",
          component: async () => import("@/pages/dashboard/Download")
        },
        {
          path: "/settings",
          name: "settings",
          component: async () => import("@/pages/dashboard/Settings")
        },
        {
          path: "/changelog",
          name: "changelog",
          component: async () => import("@/pages/dashboard/Changelog")
        },
        {
          path: "/faq",
          name: "faq",
          component: async () => import("@/pages/dashboard/Faq")
        },
        {
          path: "/miner",
          name: "miner",
          component: async () => import("@/pages/dashboard/Miner")
        },

      ]
    },
    {
      path: "/auth-pages",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "login",
          name: "login",
          component: Login
        },
        {
          path: "/accounts",
          name: "accounts",
          component: Accounts
        },
      ]
    },
    {
      path: "*",
      redirect: "/error-404",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "error-404",
          component: () => import("@/pages/samples/error-pages/error-404")
        }
      ]
    }
  ]
});
