import axios from 'axios';
import {network} from '@/config';

class Paprika {
    async fetchFeaturedPrice() {
        let preparedData = null;

        try {
            const resultBTS = (await axios.get(network.RPC + '/market/price/bts-bitshares')).data;
            const resultBTC = (await axios.get(network.RPC + '/market/price/btc-bitcoin')).data;
            const resultSTH = (await axios.get(network.RPC + '/market/price/sth-smartholdem')).data;
            const resultETH = (await axios.get(network.RPC + '/market/price/eth-ethereum')).data;
            const resultHive = (await axios.get(network.RPC + '/market/price/hive-hive')).data;
            preparedData = {
                bts: {
                    "rub": {
                        price: resultBTS.quotes.RUB ? resultBTS.quotes.RUB.price.toFixed(3) * 1 : 0,
                        change1h: resultBTS.quotes.RUB.percent_change_1h,
                        change24h: resultBTS.quotes.RUB.percent_change_24h,
                        volume24h: (resultBTS.quotes.RUB.volume_24h).toFixed(0) * 1,
                    },
                    "cny": {
                        price: resultBTS.quotes.CNY ? resultBTS.quotes.CNY.price.toFixed(4) * 1 : 0,
                        change1h: resultBTS.quotes.CNY.percent_change_1h,
                        change24h: resultBTS.quotes.CNY.percent_change_24h,
                        volume24h: (resultBTS.quotes.CNY.volume_24h).toFixed(0) * 1,
                    },
                    "usd": {
                        price: resultBTS.quotes.USD ? resultBTS.quotes.USD.price.toFixed(5) * 1 : 0,
                        change1h: resultBTS.quotes.USD.percent_change_1h,
                        change24h: resultBTS.quotes.USD.percent_change_24h,
                        volume24h: (resultBTS.quotes.USD.volume_24h).toFixed(0) * 1,
                    },
                    "eur": {
                        price: resultBTS.quotes.EUR ? resultBTS.quotes.EUR.price.toFixed(5) * 1 : 0,
                        change1h: resultBTS.quotes.EUR.percent_change_1h,
                        change24h: resultBTS.quotes.EUR.percent_change_24h,
                        volume24h: (resultBTS.quotes.EUR.volume_24h).toFixed(0) * 1,
                    },
                },
                hive: {
                    "rub": {
                        price: resultHive.quotes.RUB ? resultHive.quotes.RUB.price.toFixed(2) * 1 : 0,
                        change1h: resultHive.quotes.RUB.percent_change_1h,
                        change24h: resultHive.quotes.RUB.percent_change_24h,
                        volume24h: (resultHive.quotes.RUB.volume_24h).toFixed(0) * 1,
                    },
                    "cny": {
                        price: resultHive.quotes.CNY ? resultHive.quotes.CNY.price.toFixed(2) * 1 : 0,
                        change1h: resultHive.quotes.CNY.percent_change_1h,
                        change24h: resultHive.quotes.CNY.percent_change_24h,
                        volume24h: (resultHive.quotes.CNY.volume_24h).toFixed(0) * 1,
                    },
                    "usd": {
                        price: resultHive.quotes.USD ? resultHive.quotes.USD.price.toFixed(3) * 1 : 0,
                        change1h: resultHive.quotes.USD.percent_change_1h,
                        change24h: resultHive.quotes.USD.percent_change_24h,
                        volume24h: (resultHive.quotes.USD.volume_24h).toFixed(2) * 1,
                    },
                    "eur": {
                        price: resultHive.quotes.EUR ? resultHive.quotes.EUR.price.toFixed(3) * 1 : 0,
                        change1h: resultHive.quotes.EUR.percent_change_1h,
                        change24h: resultHive.quotes.EUR.percent_change_24h,
                        volume24h: (resultHive.quotes.EUR.volume_24h).toFixed(0) * 1,
                    },
                },
                eth: {
                    "rub": {
                        price: resultETH.quotes.RUB ? resultETH.quotes.RUB.price.toFixed(0) * 1 : 0,
                        change1h: resultETH.quotes.RUB.percent_change_1h,
                        change24h: resultETH.quotes.RUB.percent_change_24h,
                        volume24h: (resultETH.quotes.RUB.volume_24h).toFixed(0) * 1,
                    },
                    "cny": {
                        price: resultETH.quotes.CNY ? resultETH.quotes.CNY.price.toFixed(0) * 1 : 0,
                        change1h: resultETH.quotes.CNY.percent_change_1h,
                        change24h: resultETH.quotes.CNY.percent_change_24h,
                        volume24h: (resultETH.quotes.CNY.volume_24h).toFixed(0) * 1,
                    },
                    "usd": {
                        price: resultETH.quotes.USD ? resultETH.quotes.USD.price.toFixed(0) * 1 : 0,
                        change1h: resultETH.quotes.USD.percent_change_1h,
                        change24h: resultETH.quotes.USD.percent_change_24h,
                        volume24h: (resultETH.quotes.USD.volume_24h).toFixed(0) * 1,
                    },
                    "eur": {
                        price: resultETH.quotes.EUR ? resultETH.quotes.EUR.price.toFixed(0) * 1 : 0,
                        change1h: resultETH.quotes.EUR.percent_change_1h,
                        change24h: resultETH.quotes.EUR.percent_change_24h,
                        volume24h: (resultETH.quotes.EUR.volume_24h).toFixed(0) * 1,
                    },
                },
                btc: {
                    "rub": {
                        price: resultBTC.quotes.RUB ? resultBTC.quotes.RUB.price.toFixed(0) * 1 : 0,
                        change1h: resultBTC.quotes.RUB.percent_change_1h,
                        change24h: resultBTC.quotes.RUB.percent_change_24h,
                        volume24h: (resultBTC.quotes.RUB.volume_24h).toFixed(0) * 1,
                    },
                    "cny": {
                        price: resultBTC.quotes.CNY ? resultBTC.quotes.CNY.price.toFixed(0) * 1 : 0,
                        change1h: resultBTC.quotes.CNY.percent_change_1h,
                        change24h: resultBTC.quotes.CNY.percent_change_24h,
                        volume24h: (resultBTC.quotes.CNY.volume_24h).toFixed(0) * 1,
                    },
                    "usd": {
                        price: resultBTC.quotes.USD ? resultBTC.quotes.USD.price.toFixed(0) * 1 : 0,
                        change1h: resultBTC.quotes.USD.percent_change_1h,
                        change24h: resultBTC.quotes.USD.percent_change_24h,
                        volume24h: (resultBTC.quotes.USD.volume_24h).toFixed(0) * 1,
                    },
                    "eur": {
                        price: resultBTC.quotes.EUR ? resultBTC.quotes.EUR.price.toFixed(0) * 1 : 0,
                        change1h: resultBTC.quotes.EUR.percent_change_1h,
                        change24h: resultBTC.quotes.EUR.percent_change_24h,
                        volume24h: (resultBTC.quotes.EUR.volume_24h).toFixed(0) * 1,
                    },
                },
                sth: {
                    "rub": {
                        price: resultSTH.quotes.RUB ? resultSTH.quotes.RUB.price.toFixed(3) * 1 : 0,
                        change1h: resultSTH.quotes.RUB.percent_change_1h,
                        change24h: resultSTH.quotes.RUB.percent_change_24h,
                        volume24h: (resultSTH.quotes.RUB.volume_24h).toFixed(2) * 1,
                    },
                    "cny": {
                        price: resultSTH.quotes.CNY ? resultSTH.quotes.CNY.price.toFixed(4) * 1 : 0,
                        change1h: resultSTH.quotes.CNY.percent_change_1h,
                        change24h: resultSTH.quotes.CNY.percent_change_24h,
                        volume24h: (resultSTH.quotes.CNY.volume_24h).toFixed(2) * 1,
                    },
                    "usd": {
                        price: resultSTH.quotes.USD ? resultSTH.quotes.USD.price.toFixed(5) * 1 : 0,
                        change1h: resultSTH.quotes.USD.percent_change_1h,
                        change24h: resultSTH.quotes.USD.percent_change_24h,
                        volume24h: (resultSTH.quotes.USD.volume_24h).toFixed(2) * 1,
                    },
                    "eur": {
                        price: resultSTH.quotes.EUR ? resultSTH.quotes.EUR.price.toFixed(5) * 1 : 0,
                        change1h: resultSTH.quotes.EUR.percent_change_1h,
                        change24h: resultSTH.quotes.EUR.percent_change_24h,
                        volume24h: (resultSTH.quotes.EUR.volume_24h).toFixed(2) * 1,
                    },
                }
            };
        } catch(e) {

        }
        return preparedData
    }

    //https://cmc.xbts.io/v2/tickers
    async tickers() {
        let result = null;
        try {
            result = (await axios.get(network.CMC + '/v2/tickers')).data.ticker;
        } catch(e) {

        }
        return result;
    }
}


export default new Paprika()
