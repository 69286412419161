import axios from 'axios';
import {network} from '@/config';

class Xbts {
    async fetchFeaturedPrice() {
        let preparedData = null;
        try {
            const tickers = await this.tickers();
            const btsAVG = {
                RUB: (tickers.BTS_RUB.buy * 1 + tickers.BTS_RUB.sell * 1) / 2,
                CNY: (tickers.BTS_CNY.buy * 1 + tickers.BTS_CNY.sell * 1) / 2,
                USD: (tickers.BTS_USDT.buy * 1 + tickers.BTS_USDT.sell * 1) / 2,

            }
            preparedData = {
                bts: {
                    "rub": {
                        price: tickers.RUB_BTS.last ? (tickers.RUB_BTS.last * 1).toFixed(3) : 0,
                        change: tickers.RUB_BTS.change,
                    },
                    "cny": {
                        price: tickers.CNY_BTS.last ? (tickers.CNY_BTS.last * 1).toFixed(4) : 0,
                        change: tickers.CNY_BTS.change,
                    },
                    "usd": {
                        price: tickers.USDT_BTS.last ? (tickers.USDT_BTS.last * 1).toFixed(5) : 0,
                        change: tickers.USDT_BTS.change,
                    }
                },
                sth: {
                    "bts": {
                        price: tickers.BTS_STH.last ? tickers.BTS_STH.last * 1 : 0,
                        change: tickers.BTS_STH.change,
                    },
                    "rub": {
                        price: (tickers.BTS_STH.last / btsAVG.RUB).toFixed(3),
                        change: tickers.BTS_STH.change,
                    },
                    "cny": {
                        price: (tickers.BTS_STH.last / btsAVG.CNY).toFixed(4),
                        change: tickers.BTS_STH.change,
                    },
                    "usd": {
                        price: (tickers.BTS_STH.last / btsAVG.USD).toFixed(5),
                        change: tickers.BTS_STH.change,
                    }
                },
                btc: {
                    "bts": {
                        price: tickers.BTS_BTC.last ? tickers.BTS_BTC.last * 1 : 0,
                        change: tickers.BTS_BTC.change,
                    },
                    "rub": {
                        price: (tickers.BTS_BTC.last / btsAVG.RUB).toFixed(3),
                        change: tickers.BTS_BTC.change,
                    },
                    "cny": {
                        price: (tickers.BTS_BTC.last / btsAVG.CNY).toFixed(4),
                        change: tickers.BTS_BTC.change,
                    },
                    "usd": {
                        price: (tickers.BTS_BTC.last / btsAVG.USD).toFixed(5),
                        change: tickers.BTS_BTC.change,
                    }
                },


                eth: {
                    "bts": {
                        price: tickers.BTS_ETH.last ? tickers.BTS_ETH.last * 1 : 0,
                        change: tickers.BTS_ETH.change,
                    },
                    "rub": {
                        price: (tickers.BTS_ETH.last / btsAVG.RUB).toFixed(3),
                        change: tickers.BTS_ETH.change,
                    },
                    "cny": {
                        price: (tickers.BTS_ETH.last / btsAVG.CNY).toFixed(4),
                        change: tickers.BTS_ETH.change,
                    },
                    "usd": {
                        price: (tickers.BTS_ETH.last / btsAVG.USD).toFixed(5),
                        change: tickers.BTS_ETH.change,
                    }
                },

                hive: {
                    "bts": {
                        price: tickers.BTS_HIVE.last ? tickers.BTS_HIVE.last * 1 : 0,
                        change: tickers.BTS_HIVE.change,
                    },
                    "rub": {
                        price: (tickers.BTS_HIVE.last / btsAVG.RUB).toFixed(3),
                        change: tickers.BTS_HIVE.change,
                    },
                    "cny": {
                        price: (tickers.BTS_HIVE.last / btsAVG.CNY).toFixed(4),
                        change: tickers.BTS_HIVE.change,
                    },
                    "usd": {
                        price: (tickers.BTS_HIVE.last / btsAVG.USD).toFixed(5),
                        change: tickers.BTS_HIVE.change,
                    }
                },


            };
        } catch (e) {

        }
        return preparedData
    }

    //https://cmc.xbts.io/v2/tickers
    async tickers() {
        let result = null;
        try {
            result = (await axios.get(network.CMC + '/v2/tickers')).data.ticker;
        } catch (e) {

        }
        return result;
    }
}


export default new Xbts()
