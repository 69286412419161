<template>
  <section class="main-view">
    <div class="container-scroller">
      <Header/>
      <div class="container-fluid page-body-wrapper">
        <!--<SettingsPannel></SettingsPannel>-->
        <Sidebar/>
        <div class="main-panel">
          <div class="content-wrapper">
            <router-view></router-view>
          </div>
          <!-- content wrapper ends -->
          <Footer v-if="!this.$root.isMobile"/>
        </div>
        <!-- main panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>

    <vue-snotify></vue-snotify>

    <Unlock/>

    <!--<ModalCreatePool v-if="this.$root.account"/> v-if="this.$root.account"-->


    <modal width="100%" height="auto" :clickToClose="false" ref="alertNewUser" id="mLpAlertNoob" class="bg-dark"
    headerClasses="justify-content-center"

    name="alertNewUser"
           style="z-index:10000; max-height: 100vh; overflow-y: auto"
    >
    <div class="card new-user" style="overflow-y: auto;">
      <div class="text-center p-1"><img src="@/assets/images/xbts-logo.png" alt="xbts" width="100px"/>
      </div>
      <div class="card-body w-100 text-white h-100 overflow-y-scroll">
        <div class="text-center mb-4 cursor-pointer">
          <span @click="setLang('zh')" class="preview-item">
            <i class="flag-icon flag-icon-cn"></i> Chinese
          </span>
          <span @click="setLang('ru')" class="preview-item ml-3">
            <i class="flag-icon flag-icon-ru"></i> Russian
          </span>
          <span @click="setLang('en')" class="preview-item ml-3">
            <i class="flag-icon flag-icon-gb"></i> English
          </span>
        </div>

        <div v-html="$t('alert.new_user')"></div>
        <b-button @click="hideWarn" class="btn btn-success w-100">
          {{$t('alert.btn_hide')}}
        </b-button>
      </div>
    </div>
    </modal>

  </section>
</template>

<script>
import Header from "./partials/Header";
import Sidebar from "./partials/Sidebar";
import Footer from "./partials/Footer";
import Unlock from '@/components/Modals/Unlock';
//import ModalCreatePool from '@/components/Modals/ModalCreatePool';
//import SettingsPannel from "./partials/SettingsPannel";
export default {
  name: "layout",
  data() {
    return {
      alertUser: false,
    }
  },
  components: {
    Header,
    Sidebar,
    Footer,
    Unlock,
    //ModalCreatePool,
    //SettingsPannel
  },
  methods: {
    async hideWarn () {
      await this.$store.dispatch('app/hideWarning');
      this.$modal.hide('alertNewUser');
      this.alertUser = false;
    }
  },
  async created() {

  },
  async mounted() {
    setTimeout(async () => {
      this.alertUser = this.$store.getters['app/warning'];
      //this.alertUser = true;
      if (this.alertUser && this.$root.account) {
        this.$modal.show('alertNewUser')
      }
    }, 2000);

  }
};
</script>

<style>
.new-user p {
  font-size: 1em;
}
</style>
