import {xbtsAssets, nativeAssets, xbtsFiat, otherAssets} from '@/config';

export default {
    computed: {
        accountAssets() {
            return this.$store.getters['accounts/balances'] || null;
        },
    },
    methods: {
        balancesBySymbol() {
            let result = {};
            let keys = Object.keys(this.accountAssets);
            for (let i=0; i < keys.length; i++) {
                result[this.accountAssets[keys[i]].symbol] = {
                    id: this.accountAssets[keys[i]].id,
                    symbol: this.accountAssets[keys[i]].symbol,
                    precision: this.accountAssets[keys[i]].precision,
                    amount: (this.accountAssets[keys[i]].amount / 10 ** this.accountAssets[keys[i]].precision).toFixed(this.accountAssets[keys[i]].precision) * 1,
                    limit_orders: this.accountAssets[keys[i]].limit_orders / 10 ** this.accountAssets[keys[i]].precision,
                    total: this.accountAssets[keys[i]].total,
                }
            }
            return result;
        },
        assetToSymbol(asset) {
            return asset.split('.').length > 1 ? asset.split('.')[1] : asset
        },
        async knownAssets() {
            let assets = xbtsAssets.concat(nativeAssets, xbtsFiat, otherAssets);
            let result = {};
            for (let i=0; i < assets.length; i++) {
                result[assets[i]] = true;
            }
            return result;

        },
        isKnownAsset(symbol) {
            let assets = xbtsAssets.concat(nativeAssets, xbtsFiat, otherAssets);
            let assetsObj = {};
            for (let i=0; i < assets.length; i++) {
                assetsObj[assets[i]] = true;
            }
            let result = false;
            if (assetsObj[symbol]) {
                result = true;
            }
            return result;
        }
    }
}