import eventBus from '@/plugins/event-bus';
import {network} from '@/config';
import axios from 'axios';

class Explorer {

    async tickers() {
        let result = null;
        try {
            result = (await axios.get(network.RPC + '/api/tickers')).data;
        } catch(e) {

        }
        return result;
    }

    async properties() {
        let result = null;
        try {
            result = (await axios.get(network.RPC + "/api/global-properties")).data;
        } catch (e) {

        }
        return result;
    }

    async getAssets(assets = []) {
        let result = null;
        try {
            result = (await axios.post(network.RPC + "/api/assets", {assets: assets})).data;
        } catch (e) {

        }
        return result;
    }

    async getObjects(objects = []) {
        if (!objects.length) {
            return [];
        }
        let result = null;
        try {
            result = (await axios.post(network.RPC + "/api/objects", {objects: objects})).data;
        } catch (e) {

        }
        return result;
    }

    async LpHistory(data) {
        let result = null;
        try {
            result = (await axios.post(network.RPC + "/api/lp-history", data)).data;
        } catch (e) {

        }
        return result;
    }

    async getAsset(symbol) {
        let result = null;
        try {
            result = (await this.getAssets([symbol]))[0];
            if (result.options) {
                try {
                    result.options.description = JSON.parse(result.options.description);
                    result.options.description.main = result.options.description.main.replace(/[\r\n]+/gm, " ");
                } catch (e) {

                }
            }
        } catch (e) {
            console.log('err', e);
        }
        return result;
    }

    async getFullAccounts(ids = []) {
        let result = null;
        try {
            result = (await axios.post(network.RPC + "/api/accounts", {ids: ids})).data;
        } catch (e) {

        }
        return result;
    }

    async holders(asset, from = 0, to = 25) {
        asset = asset.toUpperCase();
        const assetData = await this.getAsset(asset);
        let holders = [];
        try {
            holders = (await axios.get(network.RPC + "/api/holders/" + asset + "/" + from + "/" + to)).data;
            for (let i = 0; i < holders.length; i++) {
                holders[i].amount = (holders[i].amount / 10 ** assetData.precision).toFixed(assetData.precision);
            }
        } catch(e) {

        }
        return {
            symbol: assetData.symbol,
            holders: holders
        };
    }

    async LpsA(asset) {
        let result = [];
        try {
            result = (await axios.get(network.RPC + "/api/lps-a/" + asset)).data;
        } catch(e) {

        }
        return result;
    }

    async LpsB(asset) {
        let result = [];
        try {
            result = (await axios.get(network.RPC + "/api/lps-b/" + asset)).data;
        } catch(e) {

        }
        return result;
    }

    async LpSearch(asset) {
        let result = [];
        try {
            result = (await axios.get(network.RPC + "/api/lps/" + asset)).data;
        } catch(e) {

        }
        return result;
    }

    async LpSingle(asset) {
        let result = null;
        try {
            result = (await axios.get(network.RPC + "/api/lp-single/" + asset)).data;
        } catch(e) {

        }
        return result;
    }

    async getAccount(userId) {
        let result = null;
        try {
            result = (await axios.get(network.RPC + "/api/get-account/" + userId)).data;
        } catch(e) {
            console.log('err get account', e)
        }
        return result;
    }

}

export default new Explorer()
