import {gateways} from '@/config';
import eventBus from '@/plugins/event-bus';
import axios from 'axios';

class Wallet {
    async gateways() {
        let result = {};
        const keys = Object.keys(gateways);
        try {
            for (let i = 0; i < keys.length; i++) {
                //result[keys[i]] = (await axios.get(gateways[keys[i]] + '/coins')).data;
                const gate = (await axios.get(gateways[keys[i]] + '/coins')).data;
                let assets = {};
                for (let j = 0; j < gate.length; j++) {
                    assets[gate[j].symbol] = gate[j];
                }
                result[keys[i]] = assets;
            }
        } catch (e) {

        }
        return result;
    }

    async getAddress(values) {
        let result = null;
        try {
            result = (await axios.post(gateways[values.gate] + '/wallets/' + values.coin + '/new-deposit-address', values.data)).data
        } catch (e) {
            console.log(e)
        }
        return result;
    }

    async checkAddress(values) {
        let result = null;
        try {
            result = (await axios.post(gateways[values.gate] + '/wallets/' + values.coin + '/check-address/', {address: values.address})).data
        } catch (e) {
            console.log(e)
        }
        return result;
    }

    async getScamAccounts() {
        let result = {}
        try {
            result = (await axios.get("https://raw.githubusercontent.com/XBTS/info/main/scamAccountsBTS.json")).data
        } catch (e) {
            console.log(e)
        }
        return result;
    }

    async getScamAssets() {
        let result = {}
        try {
            result = (await axios.get("https://raw.githubusercontent.com/XBTS/info/main/scamAssets.json")).data
        } catch (e) {
            console.log(e)
        }
        return result;
    }


}

export default new Wallet()
