import Vue from 'vue';
import App from './App.vue';
import router from './router';
import DashboardPlugin from './plugins/dashboard-plugin';
import BootstrapVue from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import eventBus from '@/plugins/event-bus';
import i18n from './i18n'
import synchronizer from './plugins/synchronizer';
import mixins from './mixins';
import Snotify, { SnotifyPosition} from 'vue-snotify';
import vmodal from 'vue-js-modal';
import store from './store';
import VueLetterAvatar from 'vue-letter-avatar';

Vue.use(DashboardPlugin);

Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;
Vue.prototype.$eventBus = eventBus;
Vue.use(synchronizer);
Vue.use(VueLetterAvatar);

Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.rightTop,
  }
});

Vue.use(vmodal, {

});

Vue.mixin(mixins);

/*
import {network} from '@/config';
const Dex = require('btsdex');
try {
  Dex.connect(network.NODES[0]);
} catch(e) {
  console.log('connection error', e)
}

 */

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
