import eventBus from '@/plugins/event-bus';
import {network} from '@/config';
import axios from "axios";

const Dex = require('btsdex');

//Dex.connect(network.NODES[0]);

class Chain {
    constructor() {
        //this.dex = options.dex;
    }

    async init(node = "wss://node.xbts.io/ws") {

        try {
            await Dex.connect(node);
        } catch(e) {
            console.log('err connection');
        }

        await Dex.subscribe('connected', this.connected(node));
        return true;
    }

    async connected(node = "") {
        console.log('Connected node:', node);
        await Dex.subscribe('block', this.callBlock);
    }

    async subscribeAccount(user) {
        if (user) {
            //console.log('subscribeAccount', user)
            await Dex.subscribe('account', this.changeAccount, user);
        }
    }

    async changeAccount(array) {
        //console.log('changeAccount', array)
        eventBus.emit('account:change', array)
    }

    async callBlock(obj) {
        eventBus.emit('block:new', obj)
    }

    async globalProperties() {
        let result = null;
        try {
            result = await Dex.db.get_global_properties();
        } catch (e) {

        }
        return result;
    }

    async holders(asset) {
        asset = asset.toUpperCase();
        const assetData = await Dex.assets[asset];
        let holders = [];
        try {
            holders = await Dex.assets.holders(asset, 0, 100);
            for (let i = 0; i < holders.length; i++) {
                holders[i].amount = (holders[i].amount / 10 ** assetData.precision).toFixed(assetData.precision);
            }
        } catch (e) {

        }
        return {
            symbol: assetData.symbol,
            holders: holders
        };
    }

    async getAsset(symbol) {
        let result = null;
        try {
            result = await Dex.assets[symbol];
            if (result.options) {
                try {
                    result.options.description = JSON.parse(result.options.description);
                    result.options.description.main = result.options.description.main.replace(/[\r\n]+/gm, " ");
                } catch (e) {

                }

            }
        } catch (e) {
            console.log('err', e);
        }
        return result;
    }

    async getObjects(objects = []) {
        return await Dex.db.get_objects(objects);
    }

    async getAssets(assets = []) {
        return await Dex.db.get_assets(assets);
    }

    async loginKey(values) {
        let result = null;
        try {
            result = await new Dex(values.user, values.key);
        } catch (e) {

        }
        return result;
    }

    async loginPassword(values) {
        let result = null;
        try {
            result = await Dex.login(values.user, values.key);
        } catch (e) {

        }
        return result;
    }

    async getFullAccounts(ids = []) {
        let result = null;
        try {
            result = await Dex.db.get_full_accounts(ids, false);
        } catch (e) {
            console.log('err', e)
        }
        return result;
    }


    async LpHistory(data) {
        let result = null;
        try {
            result = await Dex.history.get_liquidity_pool_history(data.id, null, null, data.limit || 5, data.op);
        } catch (e) {
            console.log('e', e)
        }
        return result;
    }

    async LpHistorySq(data) {
        let result = null;
        try {
            result = await Dex.history.get_liquidity_pool_history_by_sequence(data.id, null, null, data.limit || 5, data.op);
        } catch (e) {
            console.log('e', e)
        }
        return result;
    }

    /**
     pool_id: идентификатор пула ликвидности для запроса

     start: Целое число. Необязательный. Если указано, будут возвращены только операции, последовательность которых не больше этой.

     stop: временная метка UNIX. Необязательный. Если указано, будут возвращены только операции, произошедшие позже этого времени.

     limit: Максимальное количество операций в истории для извлечения. Необязательный. Если не указано, будет возвращена не более 101 записи.

     operation_type: Необязательный. Если указано, будут возвращены только операции, тип которых является указанным типом. В противном случае все операции будут возвращены.
     */
    async LpHistorySq(data) {
        let result = null;
        try {

            result = await Dex.history.get_liquidity_pool_history_by_sequence(data.id, data.sq || null, null, data.limit || 5, data.op);
        } catch (e) {
            console.log('e', e)
        }
        return result;
    }

    async LpExchange(data) {
        let result = null;
        try {
            let tx = data.account.newTx();
            tx.liquidity_pool_exchange(data.operation);
            result = await tx.broadcast();
            eventBus.emit('success:exchange');
        } catch (e) {
            result = {
                err: e
            };
            eventBus.emit('err:exchange', {err: e});
        }

        return result;
    }

    async LpStake(data) {
        let result = null;
        try {
            let tx = data.account.newTx();
            tx.liquidity_pool_deposit(data.operation);
            result = await tx.broadcast();
            eventBus.emit('success:stake');
        } catch (e) {
            result = {
                err: e
            };
            console.log('e', e);
            eventBus.emit('err:stake');
        }
        return result;
    }

    async LpUnStake(data) {
        let result = null;
        try {
            let tx = data.account.newTx();
            tx.liquidity_pool_withdraw(data.operation);
            result = await tx.broadcast();
            eventBus.emit('success:unstake');
        } catch (e) {
            result = {
                err: e
            };
            console.log('e', e);
            eventBus.emit('err:unstake');
        }
        return result;
    }

    async LpCreate(data) {
        let result = null;
        try {
            let tx = data.account.newTx();
            tx.liquidity_pool_create(data.operation);
            result = await tx.broadcast();
        } catch (e) {
            result = {
                err: e
            };
            console.log('e', e)
        }
        return result;
    }

    async txBroadcast(tx) {
        let result = null;
        try {
            result = await tx.broadcast();
        } catch (e) {
            result = e;
        }
        return result;
    }

    async txPrepare(data) {
        let result = {
            tx: null,
            cost: null
        };
        const operation = await data.account.transferOperation(data.recipient, data.asset, data.amount, data.memo);
        try {
            let tx = data.account.newTx();
            tx.add(operation);
            let cost = await tx.cost();
            result = {
                tx: tx,
                cost: cost,
            }
            /*
            let tx = data.account.newTx();
            let operation = data.account.transferOperation(data.recipient, data.asset, data.amount, data.memo);
            //result = await tx.broadcast();
            tx.add(operation);
             */
        } catch (e) {
            result = {
                err: e
            };
            console.log('e', e)
        }
        return result;
    }

    async vestingBalanceCreate(data) {
        let result = null;
        try {
            let tx = data.account.newTx();
            tx.vesting_balance_create(data.operation);
            result = await tx.broadcast();
        } catch (e) {
            result = {
                err: e
            };
            console.log("vesting_balance_create err:", e);
        }

        return result;
    }

    async claimStakingBalance(data) {
        let result = null;
        try {
            let tx = data.account.newTx();
            tx.vesting_balance_withdraw(data.operation);
            result = await tx.broadcast();
        } catch (e) {
            result = {
                err: e
            };
            console.log("vesting_balance_withdraw err:", e);
        }

        return result;
    }

    async accountSearch(start_symbol, limit = 50) {
        return (await Dex.db.lookup_accounts(start_symbol, limit));
    }

    /**
     name: name,
     owner: req.body.account.owner_key,
     active: req.body.account.active_key,
     memo: req.body.account.memo_key,
     referrer: req.body.account.referrer,
     */
    async accountRegister(options) {
        let result = null;
        try {
            result = (await axios.post(network.FAUCET + '/api/v1/accounts', {
                account: options
            })).data;
        } catch(e) {
            console.log('err registration', e)
        }
        return result;
    }

}


export default new Chain()
