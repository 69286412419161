exports.network = {
    "EXPLORER": "https://ex.xbts.io",
    "NODES": [
        //"ws://192.168.31.56:8090",          // local dev
        "wss://node.xbts.io/ws",
        "wss://cloud.xbts.io/ws",
        "wss://public.xbts.io/ws",
        "wss://btsws.roelandp.nl/ws",
        "wss://eu.nodes.bitshares.ws",
        "wss://api.dex.trading/",
        "wss://api.bts.mobi/ws",
        "ws://localhost:8090",


    ],
    //"RPC": "http://127.0.0.1:8021",           // local dev
    "RPC": "https://explorer3-api.xbts.io",     // 94.130.220.87 wss://node.xbts.io/ws
    //"RPC": "https://explorer-api.xbts.io",    // 23.88.116.163
    "WS": "wss://explorer3-api.xbts.io/ws",
    "FAUCET": "https://faucet.xbts.io",
    "CMC": "https://cmc.xbts.io",
    //"FAUCET": "https://testnet-faucet.xbts.io",
    "RPC_EXPLORER":
        {
            "RPC": [
                "https://explorer3-api.xbts.io",
                "https://explorer2-api.xbts.io",
                "https://explorer-api.xbts.io",
            ],
            "WS": [
                "wss://explorer3-api.xbts.io/ws",
                "wss://explorer2-api.xbts.io/ws",
                "wss://explorer-api.xbts.io/ws",
            ]
        },
};

exports.currSymbols = {
    usd: "$",
    rub: "₽",
    cny: "¥"
};

exports.search = {
    "XCH":"XBTSX.XCH",
    "EGC": "XBTSX.EGC",
    "STH": "XBTSX.STH",
    "USDT": "XBTSX.USDT",
    "USDC": "XBTSX.USDC",
    "LTC": "XBTSX.LTC",
    "XBTSX.USDC": "XBTSX.USDC",
    "BTC": "XBTSX.BTC",
    "EOS": "XBTSX.EOS",
    "MDL": "XBTSX.MDL",
    "TRD": "XBTSX.TRD",
    "ETH": "XBTSX.ETH",
    "HIVE": "XBTSX.HIVE",
    "RUB": "XBTSX.RUB",
    "DASH": "XBTSX.DASH",
    "DOGE": "XBTSX.DOGE",
    "BTS": "BTS",
    "CNY": "CNY",
    "USD": "USD",
    "EUR": "EUR",
    "YOYOW": "YOYOW",
    "DEFI": "DEFI",
    "BEOS": "BEOS",
    "SILVER": "SILVER",
    "GOLD": "GOLD",
    "BTWTY": "BTWTY",
    "TWENTIX": "TWENTIX",
    "NGN": "CRUDE.NGN",
    "CRUDE.NGN": "CRUDE.NGN",
    "GDEX.EOS": "GDEX.EOS",
    "GDEX.BTC": "GDEX.BTC",
    "HONEST.USD": "HONEST.USD",
    "IOB.XRP": "IOB.XRP",
    "EVRAZ": "EVRAZ",
    "CRUDE.LVRG": "CRUDE.LVRG",
    "CRUDE": "CRUDE.NGN",
    "RVN": "XBTSX.RVN",
    "AXAI": "XBTSX.AXAI",
    "QUINT": "QUINT",
    "GOLDBACK": "GOLDBACK",
    "TUSC": "XBTSX.TUSC",
    "XAUT": "XBTSX.XAUT",
    "NESS": "XBTSX.NESS",
    "SCH": "XBTSX.SCH",
    "NCH": "XBTSX.NCH",
    "BNB": "XBTSX.BNB",
    "TCG": "XBTSX.TCG",
    "BCH": "XBTSX.BCH",
    "RDD": "XBTSX.RDD",
    "ETC": "XBTSX.ETC",
   //"BUSD": "XBTSX.BUSD",
    "TRX": "XBTSX.TRX",
    "RUBLE": "RUBLE",
    "PPC": "XBTSX.PPC",
    "SHIB": "XBTSX.SHIB",
    "ZEC": "XBTSX.ZEC",
    "NVC": "XBTSX.NVC",
    "RTM": "XBTSX.RTM",
    "POST": "XBTSX.POST",
    "HONEST": "HONEST",
    "HONEST.MONEY": "HONEST.MONEY",
    "EMC": "XBTSX.EMC",
    "XCCX": "XBTSX.XCCX",
    "WRAM":"XBTSX.WRAM",
    "DPR":"XBTSX.DPR",
    "PEP":"XBTSX.PEP",


};

exports.nativeAssets = [
    "BTS",
    "CNY",
    "EUR",
    "USD",
    "RUBLE",
    "GBP",
    "GOLD",
    "SILVER",
    "CHF",
    "CAD",
    "MXN",
    "JPY",
    "BTC",
];

exports.xbtsAssets = [
    "BTS",
    "XBTSX.BTC",
    "XBTSX.STH",
    "XBTSX.XCH",
    "XBTSX.ETH",
    "XBTSX.USDT",
    "XBTSX.USDC",
    "XBTSX.EOS",
    "XBTSX.LTC",
    "XBTSX.DOGE",
    "XBTSX.PEP",
    "XBTSX.HIVE",
    "XBTSX.HBD",
    "XBTSX.ETC",
    "XBTSX.DASH",
    "XBTSX.BCH",
    "XBTSX.ZEC",
    "XBTSX.RVN",
    "XBTSX.XAUT",
    "XBTSX.BNB",
    "XBTSX.DPR",
    "XBTSX.WRAM",

    "XBTSX.DAI",
    "XBTSX.EGC",
    "XBTSX.RDD",
    "XBTSX.NVC",
    "XBTSX.EMC",
    "XBTSX.NMC",
    "XBTSX.ONION",
    "XBTSX.BTG",
    "XBTSX.AUR",

    "XBTSX.WAVES",
    "XBTSX.TRX",
    "XBTSX.PPC",
    "XBTSX.SHIB",
    "XBTSX.PEPE",
    "XBTSX.XRP",
    "XBTSX.POST",
    "XBTSX.GRS",
    "XBTSX.EXR",
    "XBTSX.XCCX",
    "XBTSX.AXAI",
    "XBTSX.TRD",
    "XBTSX.SCH",
    "XBTSX.VTC",
    "XBTSX.PIVX",
    "XBTSX.NESS",
    "XBTSX.HVQ",
    "XBTSX.TCG",
    "XBTSX.NCH",
    "XBTSX.LUNR",
    "XBTSX.MANA",
    "XBTSX.BAT",
    "XBTSX.VITE",
    "XBTSX.GODS",
    "XBTSX.FLUX",
    "XBTSX.QTUM",
    "XBTSX.ZIL",
    "XBTSX.RTM",

    "XBTSX.MDL",
    "XBTSX.SKY",
    "XBTSX.42",
    "XBTSX.FIL",
    "XBTSX.ATRI",
    //"XBTSX.BSV",
    //"XBTSX.GUAP",
    //"XBTSX.XBB",
    //"XBTSX.LUNAREUM",
    //"XBTSX.TUSC",
    //"XBTSX.UNI",
    //"XBTSX.BUSD",
    //"XBTSX.TUSD",
    //"XBTSX.HT",
];

exports.xbtsFiat = [
    "XBTSX.RUB",
    "XBTSX.USD",
    "XBTSX.EUR",
];


exports.otherAssets = [
    "BTWTY.EOS",
    "DEFI",
    "EVRAZ",
    "CRUDE.NGN",
    "YOYOW",
    "TWENTIX",
    "BTWTY",
    "BEOS",
    "GDEX.BTC",
    //"GDEX.ETH",
    "GDEX.USDT",
    "GDEX.EOS",
    "HONEST.USD",
    "IOB.XRP",
    "QUINT",
    "QUINT.GOLDBACK",
    "GOLDBACK"
];

exports.defiAssets = [
    "SMARTHOLDEM",
];

exports.binAssets = [];

exports.permission_flags = {
    charge_market_fee: 0x01 /**< an issuer-specified percentage of all market trades in this asset is paid to the issuer */,
    white_list: 0x02 /**< accounts must be whitelisted in order to hold this asset */,
    override_authority: 0x04 /**< issuer may transfer asset back to himself */,
    transfer_restricted: 0x08 /**< require the issuer to be one party to every transfer */,
    disable_force_settle: 0x10 /**< disable force settling */,
    global_settle: 0x20 /**< allow the bitasset issuer to force a global settling -- this may be set in permissions, but not flags */,
    disable_confidential: 0x40 /**< allow the asset to be used with confidential transactions */,
    witness_fed_asset: 0x80 /**< allow the asset to be fed by witnesses */,
    committee_fed_asset: 0x100, /**< allow the asset to be fed by the committee */
};

exports.uia_permission_mask = [
    "charge_market_fee",
    "white_list",
    "override_authority",
    "transfer_restricted",
    "disable_confidential"
];

exports.fee_grouping = {
    general: [0, 25, 26, 27, 28, 32, 33, 37, 39, 41, 49, 50, 52],
    asset: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 38, 43, 44, 47, 48],
    market: [1, 2, 3, 4, 45, 46],
    account: [5, 6, 7, 8, 9],
    business: [20, 21, 22, 23, 24, 29, 30, 31, 34, 35, 36],
    pool: [59, 60, 61, 62, 63]
};

exports.operations = {
    transfer: 0,
    limit_order_create: 1,
    limit_order_cancel: 2,
    call_order_update: 3,
    fill_order: 4,
    account_create: 5,
    account_update: 6,
    account_whitelist: 7,
    account_upgrade: 8,
    account_transfer: 9,
    asset_create: 10,
    asset_update: 11,
    asset_update_bitasset: 12,
    asset_update_feed_producers: 13,
    asset_issue: 14,
    asset_reserve: 15,
    asset_fund_fee_pool: 16,
    asset_settle: 17,
    asset_global_settle: 18,
    asset_publish_feed: 19,
    witness_create: 20,
    witness_update: 21,
    proposal_create: 22,
    proposal_update: 23,
    proposal_delete: 24,
    withdraw_permission_create: 25,
    withdraw_permission_update: 26,
    withdraw_permission_claim: 27,
    withdraw_permission_delete: 28,
    committee_member_create: 29,
    committee_member_update: 30,
    committee_member_update_global_parameters: 31,
    vesting_balance_create: 32,
    vesting_balance_withdraw: 33,
    worker_create: 34,
    custom: 35,
    assert: 36,
    balance_claim: 37,
    override_transfer: 38,
    transfer_to_blind: 39,
    blind_transfer: 40,
    transfer_from_blind: 41,
    asset_settle_cancel: 42,
    asset_claim_fees: 43,
    fba_distribute: 44,
    bid_collateral: 45,
    execute_bid: 46,
    asset_claim_pool: 47,
    asset_update_issuer: 48,
    htlc_create: 49,
    htlc_redeem: 50,
    htlc_redeemed: 51,
    htlc_extend: 52,
    htlc_refund: 53,
    custom_authority_create: 54,
    custom_authority_update: 55,
    custom_authority_delete: 56,
    ticket_create: 57,
    ticket_update: 58,
    liquidity_pool_create: 59,
    liquidity_pool_delete: 60,
    liquidity_pool_deposit: 61,
    liquidity_pool_withdraw: 62,
    liquidity_pool_exchange: 63,
    samet_fund_create: 64,
    samet_fund_delete: 65,
    samet_fund_update: 66,
    samet_fund_borrow: 67,
    samet_fund_repay: 68,
    credit_offer_create: 69,
    credit_offer_delete: 70,
    credit_offer_update: 71,
    credit_offer_accept: 72,
    credit_deal_repay: 73,
    credit_deal_expired: 74    // VIRTUAL
};

exports.opById = {
    "0": "transfer",
    "1": "limit_order_create",
    "2": "limit_order_cancel",
    "3": "call_order_update",
    "4": "fill_order",
    "5": "account_create",
    "6": "account_update",
    "7": "account_whitelist",
    "8": "account_upgrade",
    "9": "account_transfer",
    "10": "asset_create",
    "11": "asset_update",
    "12": "asset_update_bitasset",
    "13": "asset_update_feed_producers",
    "14": "asset_issue",
    "15": "asset_reserve",
    "16": "asset_fund_fee_pool",
    "17": "asset_settle",
    "18": "asset_global_settle",
    "19": "asset_publish_feed",
    "20": "witness_create",
    "21": "witness_update",
    "22": "proposal_create",
    "23": "proposal_update",
    "24": "proposal_delete",
    "25": "withdraw_permission_create",
    "26": "withdraw_permission_update",
    "27": "withdraw_permission_claim",
    "28": "withdraw_permission_delete",
    "29": "committee_member_create",
    "30": "committee_member_update",
    "31": "committee_member_update_global_parameters",
    "32": "vesting_balance_create",
    "33": "vesting_balance_withdraw",
    "34": "worker_create",
    "35": "custom",
    "36": "assert",
    "37": "balance_claim",
    "38": "override_transfer",
    "39": "transfer_to_blind",
    "40": "blind_transfer",
    "41": "transfer_from_blind",
    "42": "asset_settle_cancel",
    "43": "asset_claim_fees",
    "44": "fba_distribute",
    "45": "bid_collateral",
    "46": "execute_bid",
    "47": "asset_claim_pool",
    "48": "asset_update_issuer",
    "49": "htlc_create",
    "50": "htlc_redeem",
    "51": "htlc_redeemed",
    "52": "htlc_extend",
    "53": "htlc_refund",
    "54": "custom_authority_create",
    "55": "custom_authority_update",
    "56": "custom_authority_delete",
    "57": "ticket_create",
    "58": "ticket_update",
    "59": "liquidity_pool_create",
    "60": "liquidity_pool_delete",
    "61": "liquidity_pool_deposit",
    "62": "liquidity_pool_withdraw",
    "63": "liquidity_pool_exchange",
    "64": "samet_fund_create",
    "65": "samet_fund_delete",
    "66": "samet_fund_update",
    "67": "samet_fund_borrow",
    "68": "samet_fund_repay",
    "69": "credit_offer_create",
    "70": "credit_offer_delete",
    "71": "credit_offer_update",
    "72": "credit_offer_accept",
    "73": "credit_deal_repay",
    "74": "credit_deal_expired"
};

exports.gateways = {
    "coins": "https://apis.xbts.io/api/v2",
    "waves": "https://apis.xbts.io/waves",
    "bsc": "https://apis.xbts.io/bsc",
    "eth": "https://apis.xbts.io/eth",
    "eos": "https://apis.xbts.io/eos",
    "fiat": "https://apis.xbts.io/fiat",
    //"heco": "https://apis.xbts.io/heco",
    //"bnb": "https://apis.xbts.io/bnb",
};

exports.smartholder = {
    API: 'https://smartholder.xbts.io',
    minDeposit: 100000,
    assetId: "1.3.4099"
};

