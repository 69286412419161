import axios from 'axios'
import {smartholder} from '@/config'

class SmartHolder {
  async getHolders() {
    let dataHolders = null;
    try {
      dataHolders = (await axios.get(smartholder.API + '/api/public/percents')).data
    } catch(e) {

    }
    return dataHolders
  }

  async getAssetsPay() {
    let result = null;
    try {
      result = (await axios.get(smartholder.API + '/api/get/assets')).data;
    } catch (e) {
      console.log('err get smartholder pay', e)
    }
    return result
  }

  async getAssetsNoq() {
    return (await axios.get(smartholder.API + '/api/get/noassets')).data
  }

  async latestReportCountRemote() {
    return (await axios.get('https://news.xbts.io/xbtsreports/count')).data
  }

  async fetchLatestReports() {
    return (await axios.get('https://news.xbts.io/xbtsreports?_limit=12&_sort=id:DESC')).data
  }

  async getMyPayments(userId) {
    let result = {};
    try {
      result = (await axios.get(smartholder.API + '/api/payments/' + userId)).data;
    } catch (e) {
      console.log('err getMyPayments', e)
    }
    return result
  }
}


export default new SmartHolder()
