<template>
  <b-navbar
    id="template-header"
    class="default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
    type="dark"
    toggleable="lg"
  >
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <router-link class="navbar-brand brand-logo mr-5" to="/">
        <img v-if="month > 0 && month < 11" src="@/assets/images/xbts-logo.png" alt="xbts" />
        <img v-if="month > 10" src="@/assets/images/logo_newyear.png" alt="xbts new year" />
        <img v-if="month === 0" src="@/assets/images/logo_cn.png" alt="xbts new year" />

      </router-link>

      <router-link class="navbar-brand brand-logo-mini" to="/">
        <img src="@/assets/images/favicon.png" alt="xbts" />
      </router-link>

    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0">
      <button
        class="navbar-toggler navbar-toggler align-self-center d-lg-block"
        type="button"
        @click="toggleSidebar()"
      >
        <span class="ti-layout-grid2"></span>
      </button>
      <b-navbar-nav class="navbar-nav-right ml-auto">

        <b-nav-item v-if="this.$root.account" @click="routeTo('/wallet')" class="preview-list mr-4" v-b-tooltip.html.bot>
          <div class="text-primary">
          <i class="icon-wallet mr-2 icon-16"></i><span v-show="!this.$root.isMobile">{{$t('wallet.wallet')}}</span>
          </div>
        </b-nav-item>

        <b-nav-item-dropdown v-if="this.$root.account" right class="nav-profile" title="Profile" v-b-tooltip.html.bot>
          <template slot="button-content">
                  <span
                          class="nav-link dropdown-toggle"
                          id="profileDropdown"
                          href="javascript:void(0);"
                          data-toggle="dropdown"
                          aria-expanded="false"
                  >
                    <div class="nav-profile-img text-success" v-if="this.$root.account">
                      <i class="icon-user icon-16"></i> <span v-if="this.$root.account.account" class="pt-1">{{this.$root.account.account.name.substr(0, 2)}}**{{this.$root.account.account.name.substr(-2)}}</span>
                    </div>
                  </span>
          </template>

          <b-dropdown-item class="preview-item" @click="routeTo('/account/' + $root.account.account.name)">
            <i class="icon-user text-primary"></i> {{this.$root.account.account ? this.$root.account.account.name: 'Profile'}}
          </b-dropdown-item>
          <b-dropdown-item class="preview-item" @click="routeTo('/accounts')">
            <i class="icon-people text-primary"></i> {{$t('app.my_accounts')}}
          </b-dropdown-item>

          <b-dropdown-item class="preview-item" @click="routeTo('/settings')">
            <i class="ti-settings text-primary"></i> {{$t('app.settings')}}
          </b-dropdown-item>

          <b-dropdown-item class="preview-item" @click="logout">
            <i class="ti-power-off text-primary"></i> {{$t('app.logout')}}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item v-if="!this.$root.account" @click="routeTo('/auth-pages/login')" class="preview-list mr-4" v-b-tooltip.html.bot>
          <span class="text-primary"><i class="icon-login ml-2 mr-2 icon-16"></i> {{$t('app.login')}}</span>
        </b-nav-item>

        <!--
        <b-nav-item v-if="!this.$root.account" @click="showModalUnlock" class="preview-list mr-4">
          <span class="text-primary"><i class="icon-lock ml-2 mr-2 icon-16"></i></span>
        </b-nav-item>
        -->



        <b-nav-item-dropdown right class="preview-list">
          <template slot="button-content">
                  <span
                          class="nav-link dropdown-toggle"
                          id="languageDropdown"
                          href="javascript:void(0);"
                          data-toggle="dropdown"
                          aria-expanded="false"
                  >
                    <div class="nav-profile-img text-success">
                      <i v-show="this.$i18n.locale === 'zh'" class="flag-icon flag-icon-cn"></i>
                      <i v-show="this.$i18n.locale === 'ru'" class="flag-icon flag-icon-ru"></i>
                      <i v-show="this.$i18n.locale === 'en'" class="flag-icon flag-icon-gb"></i>
                    </div>
                  </span>
          </template>
          <b-dropdown-item @click="setLang('zh')" class="preview-item">
            <i class="flag-icon flag-icon-cn"></i> Chinese
          </b-dropdown-item>
          <b-dropdown-item @click="setLang('ru')" class="preview-item">
            <i class="flag-icon flag-icon-ru"></i> Russian
          </b-dropdown-item>
          <b-dropdown-item @click="setLang('en')" class="preview-item">
            <i class="flag-icon flag-icon-gb"></i> English
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <!--
        <b-nav-item class="nav-logout d-none d-lg-block nav-settings">
          <i class="ti-more" @click="togglesettingsPannel()"></i>
        </b-nav-item>
        -->

      </b-navbar-nav>

      <button
        class="navbar-toggler navbar-toggler-right align-self-center"
        type="button"
        @click="toggleMobileSidebar()"
      >
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
  </b-navbar>
</template>

<script>
import eventBus from '@/plugins/event-bus';
export default {
  name: "app-header",
  data() {
    return {
      month: 1,
      day: 0,
    }
  },
  methods: {
    async logout() {
      await this.$router.push('/auth-pages/login')
    },
    async showModalUnlock() {
      eventBus.emit('modal:unlock');
    },
    async toggleSidebar() {
      document.querySelector("body").classList.toggle("sidebar-icon-only");
      await this.$store.dispatch('settings/setSidebar');
    },
    toggleMobileSidebar: async () => {
      document.querySelector("#sidebar").classList.toggle("active");
    },
    togglesettingsPannel: () => {
      //document.querySelector("#right-sidebar").classList.toggle("open");
    }
  },
  async created() {
    const moonLanding = new Date(); //11 - dec, 0 - jan
    this.month = moonLanding.getMonth();
    this.day = moonLanding.getDate()
  }
};
</script>

<style scoped></style>
