import packageJson from '../../package.json';

export default {
    methods: {
        currentVersion() {
            return packageJson.version;
        },
        async routeTo(page) {
            if (page !== this.$route.page) {
                await this.$router.push(page);
            }

        },
        async setLang(value) {
            await this.$store.dispatch('app/setLanguage', value);
            let htmlEl = document.querySelector("html");
            //htmlEl.setAttribute('dir','rtl');
            htmlEl.setAttribute('lang', value);
        },
        async doCopyText(text) {
            await this.$copyText(text);
            this.$snotify.info('Copied', {
                timeout: 1000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                position: 'rightBottom'
            });
        },
        async notifySuccess(text = 'Success', position = 'rightBottom') {
            this.$snotify.clear();
            this.$snotify.success(text, {
                timeout: 3000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                position: position
            });
        },
        async notifyError(text = 'Error', position = 'rightBottom') {
            this.$snotify.clear();
            this.$snotify.error(text, {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                position: position
            });
        }
    }
}
