<template>
    <div>
        <modal ref="modalUnlock" id="mUnlock" class="bg-dark"
               headerClasses="justify-content-center"
               name="modalUnlock"
        >
            <div class="card">
                <div class="card-body vh-100">
                    <h4 class="text-center">Unlock Account</h4>
                    <div>
                        <b-form-group
                                label="Enter Pin"
                                label-for="input_pin">
                            <b-input-group>
                                <b-form-input
                                        @input="changePin"
                                        id="input_pin"
                                        placeholder="enter pin code"
                                        type="text" v-model="pin"></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>

                    <div class="card-footer">
                        <div class="row">
                            <div  class="col-md-6 col-sm-6 col-xs-6 mb-2">
                                <b-button @click="unlock" class="text-uppercase w-100" variant="danger">{{$t('app.confirm')}}</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import eventBus from '@/plugins/event-bus';

export default {
    name: "Unlock",
    data() {
        return {
            show: false,
            pin: '',
        }
    },
    methods: {
        async hide() {
            this.show = false;
            this.$modal.hide('modalReset');
        },
        async changePin() {

        },
        async unlock() {

        },
    },
    async created() {
        eventBus.on('modal:unlock', async () => {
            this.show = true;
            this.$modal.show('modalUnlock');
        });
    }
}
</script>

<style scoped>

</style>
