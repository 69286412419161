<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
          © 2018 - {{year}} <a title="DEX Exchange" href="https://xbts.io" target="_blank">XBTS DEX & DeFi</a> v.{{currentVersion()}}
        </span>
        <span class="ml-3">
          <a title="XBTS Telegram" href="https://t.me/xbtsio"><i class="fa fa-paper-plane-o" style="font-size: 15px;"></i> Telegram</a>
        </span>
        <span class="ml-3 text-info">
          <a target="_blank" title="BitShares Explorer" href="https://blocksights.info/#/dashboard"><i class="fa fa-cubes" style="font-size: 16px;"></i> Explorer</a>
        </span>
        <span class="ml-3 text-info">
          <a target="_blank" title="Hive Blog" href="https://hive.blog/@xbts"><i class="fa fa-newspaper-o" style="font-size: 16px;"></i> News</a>
        </span>
        <span class="ml-3 text-info">
          <a target="_blank" title="Cross-Chains Status" href="https://xbts.io/assets"><i class="fa fa-list-ul" style="font-size: 16px;"></i> Cross-Chains Status</a>
        </span>

        <span v-if="block" v-b-tooltip.html.top title="Chain Height"
              class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center text-info">
          <i class="icon-layers"></i> {{block}}
        </span>
      </div>
    </footer>
  </section>
</template>

<script>
import eventBus from '@/plugins/event-bus';
export default {
  name: "app-footer",
  data() {
    return {
      block: null,
      year: new Date().getFullYear(),
    }
  },

  async created() {
    eventBus.on('block:new', async (data) => {
      this.block = data[0].head_block_number;
    });
  },

};
</script>
