import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import localforage from 'localforage';
import {pullAll, keys} from 'lodash';

import vuexPersistReady from '@/store/plugins/vuex-persist-ready';

import network from '@/store/modules/network';
import app from '@/store/modules/app';
import accounts from '@/store/modules/accounts';
import assets from '@/store/modules/assets';
import wallet from '@/store/modules/wallet';
import lp from '@/store/modules/lp';
import session from '@/store/modules/session';
import smartholder from '@/store/modules/smartholder';
import transaction from '@/store/modules/transaction';
import paprika from '@/store/modules/paprika';
import settings from '@/store/modules/settings';
import xbts from '@/store/modules/xbts';

Vue.use(Vuex);

const modules = {
  network: network,
  app: app,
  accounts: accounts,
  assets: assets,
  wallet: wallet,
  lp: lp,
  session: session,
  smartholder: smartholder,
  transaction: transaction,
  paprika: paprika,
  settings: settings,
  xbts: xbts,
};

// Modules that should not be persisted
const ignoreModules = [];

const vuexPersist = new VuexPersistence({
  // It is necessary to enable the strict mode to watch to mutations, such as `RESTORE_MUTATION`
  strictMode: true,
  asyncStorage: true,
  key: 'xbtsadvanced',
  storage: localforage,
  modules: pullAll(keys(modules), ignoreModules)
});

export default new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== 'production',
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
  },
  actions: {
    resetData() {
      return localforage.clear()
    },
  },
  plugins: [
    vuexPersist.plugin,
    vuexPersistReady
  ]
})
