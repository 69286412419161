<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/">
            <i class="ti-home menu-icon"></i>
            <span class="menu-title">{{$t('app.dashboard')}}</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/pools">
            <i class="icon-magnifier menu-icon text-success"></i>
            <span class="menu-title">DeFi AMMob</span>
          </router-link>
        </li>
        <!--
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/defi">
            <i class="icon-star menu-icon text-warning"></i>
            <span class="menu-title">DeFi AMMOB</span>
          </router-link>
        </li>
        -->

        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/smartholder">
            <i class="icon-pie-chart menu-icon" style="color: deeppink;"></i>
            <span class="menu-title">SmartHolder</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/download">
            <i class="icon-cloud-download menu-icon"></i>
            <span class="menu-title">{{$t('app.download_app')}}</span>
          </router-link>
        </li>

        <li class="nav-item" v-on:click="collapseAll">
          <a class="nav-link" target="_blank" href="https://ex.xbts.io">
            <i class="icon-chart menu-icon"></i>
            <span class="menu-title">{{$t('app.dex')}}</span>
          </a>
        </li>


        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/faq">
            <i class="icon-list menu-icon"></i>
            <span class="menu-title">F.A.Q.</span>
          </router-link>
        </li>


      </ul>
    </nav>
  </section>
</template>

<script>

import eventBus from "@/plugins/event-bus";

export default {
  name: "sidebar",
  data() {
    return {
      collapses: [{ show: false }, { show: false }, { show: false }]
    };
  },
  methods: {
    async showLpCreate() {
      await eventBus.emit('modal:lpcreate');
    },
    collapseAll() {
      let exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        let elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    }
  },
  mounted() {
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function(el) {
      el.addEventListener("mouseover", function() {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function() {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  },

  watch: {
    $route() {
      document.querySelector("#sidebar").classList.remove("active");
    }
  }

};
</script>
