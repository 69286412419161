<!--
<script src="store/modules/network.js"></script>
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import {network} from '@/config';
//const Dex = require('btsdex');
export default {
  name: 'app',

  async created() {




    //console.log(navigator.userAgentData)
    this.$root.isMobile = window.innerWidth < 768;
    if (navigator.language === 'ru-RU') {
      this.$i18n.locale = 'ru'
    } else {
      this.$i18n.locale = 'en'
    }

    let htmlEl = document.querySelector("html");
    //htmlEl.setAttribute('dir','rtl');
    htmlEl.setAttribute('lang', this.$i18n.locale);

    if (!this.$root.pin) {
      //await this.$router.push('/auth-pages/login');
    }

    this.$store._vm.$on('vuex-persist:ready', async () => {

      const locale = await this.$store.getters['app/language'];
      if (locale) {
        this.$i18n.locale = locale
      }
      //await this.$store.dispatch('network/connect');
      if (!this.$root.isMobile) {
        if (this.$store.getters['settings/sidebar']) {
          document.querySelector("body").classList.toggle("sidebar-icon-only");
        }
      }


      let storedNode = await this.$store.getters['network/currentNode'];
      //console.log(storedNode)
      if (storedNode === 'wss://fsn5.xbts.io/ws') {
        storedNode = "wss://node.xbts.io/ws"
      }
      if (storedNode) {

        await this.$store.dispatch('app/init', storedNode);
/*
        try {
          Dex.connect(storedNode);
          //console.log('connect', storedNode)
        } catch(e) {
          Dex.connect(network.NODES[0]);
          //console.log('connection error', e)
        }

 */
      } else {
        await this.$store.dispatch('app/init', network.NODES[0]);
      }

      //await this.$store.dispatch('app/init');

      this.$synchronizer.defineAll();
      this.$synchronizer.ready();
    });
  }
}
</script>

<style lang="scss">
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../node_modules/ti-icons/css/themify-icons.css";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "../node_modules/vue-snotify/styles/material.css";
@import "../node_modules/codemirror/lib/codemirror.css";
@import "../node_modules/fullcalendar/dist/fullcalendar.css";
@import "../node_modules/c3/c3.min.css";
@import "../node_modules/chartist/dist/chartist.min.css";
@import "./assets/scss/style";
</style>
