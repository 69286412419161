import NumberFormat from 'number-format.js'
import moment from 'moment';

export default {
    methods: {
        numTransform(value) {
            let result = value;
            if (value > 10000) {
                result = (value / 1000).toFixed(2) + 'k';
            }
            if (value > 999999) {
                result = (value / 1000000).toFixed(2) + 'M';
            }
            if (value > 1000000000) {
                result = (value / 1000000000).toFixed(3) + 'Bil';
            }
            if (value > 1000000000000) {
                result = (value / 1000000000000).toFixed(3) + 'Tril';
            }
            return result;
        },
        formatNum(value, mask = '# ##0.00') {
            return NumberFormat(mask, value)
        },
        formatTime(tm, format = "DD-MM-YYYY HH:mm:ss") {
            return moment.utc(tm).local().format(format);
        },
        formatBalance(amount, precision) {
            return this.formatNum(amount, '# ##0.' + (10 ** precision).toFixed(precision).substring(1))
        },
        formatAssetPrefix(asset) {
            const arrAsset = asset.split('.');
            const prefix = arrAsset[1] ? arrAsset[0] + "." : "";
            const symbol = arrAsset[1] ? arrAsset[1] : asset;
            return {
                prefix: prefix,
                symbol: symbol,
                html: '<span class="small-x2">' + prefix + '</span><span class="text-white">' + symbol + '</span>',
            }
        },
        timeStringToGrapheneDate(time_string) {
            if (!time_string) return new Date("1970-01-01T00:00:00.000Z");
            if (!/Z$/.test(time_string)) {
                time_string = time_string + "Z";
            }
            return new Date(time_string);
        },
    }
}
